// auth routes
export default [
    {
        path: "",
        component: () => import('@/layouts/extranetLayout.vue'),
        children: [
            { path: "login", name: 'Login', component: () => import('@/views/auth/signin/Login.vue') },
            { path: "register", name: "Register", component: () => import('@/views/auth/signup/Register.vue') },
            { path: "password/email", name: "SendEmail", component: () => import('@/views/auth/reset/SendEmail.vue') },
            { path: "password/reset", name: "ResetPassword", component: () => import('@/views/auth/reset/ResetPassword.vue') },
        ]
    }
]
