import store from "../store";

/**
 * Este middleware verifica se o usuário está autenticado
 * @param {Object} to 
 * @param {Object} from 
 * @param {Function} next 
 * @returns {Object}
 */
export default async function auth(to, from, next) {
  if (!store.getters["auth/loggedIn"]) {
    return { status: false, route: "Login" };
  }
  return { status: true };
}
