import store from "../store";

/**
 * Middleware que verifica se o usuário tem permissão para acessar a rota solicitada.
 * Se a rota tiver uma meta chamada parent, ele verifica a permissão da rota pai.
 * 
 * @param {Object} to 
 * @param {Object} from 
 * @param {Function} next 
 * @returns {Object}
 */
export default async function permission(to, from, next) {
  try {
    const routeToVerify = to.meta?.parent || to.name;
    const hasPermission = await store.dispatch('auth/checkRoutePermission', routeToVerify);
    if (hasPermission) {
      return { status: true };
    } else {
      return { status: false, route: 'Home' };
    }
  } catch (error) {
    return  { status: false, route: 'Home' };
  }
}
