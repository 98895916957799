import authService from "../services/auth.service";
import { getVipData } from "../services/main.service";
import { saveToLocalStorage, loadFromLocalStorage } from "../assets/js/crypto-utils.js";

const user = JSON.parse(localStorage.getItem("token"));

export const auth = {
  namespaced: true,
  state: {
    loggedIn: !!user,
    userRoutePermissions: [],
    userVIP: false,
    emailUserVIP: null
  },
  actions: {
    async initializeApp({ dispatch, state }) {
      if (state.loggedIn) {
        await dispatch("fetchUserVIP");
      }
    },
    async login({ commit }, userData) {
      await authService.login(userData);
      commit("logMeIn");
    },

    async logout({ commit }) {
      try {
        await authService.logout();
      } finally {
        commit("logMeOut");
      }
    },

    async register({ commit }, userData) {
      try {
        const response = await authService.register(userData);
        return response;
      } catch (error) {
        return error.response.data;
      }
    },
    async acessoAntecipado ({ commit }, userData) {
      try {
        const response = await authService.acessoAntecipado(userData);
        return response;
      } catch (error) {
        return error;
      }
    },
    async fetchUserRoutePermissionsFromBackend({ commit }) {
      try {
        const userData = loadFromLocalStorage('userData');
        const response = await Axios.useAxios('get', '/user/route-permissions/'+userData.id);
        commit('setUserRoutePermissions', response.data.routes);
        return response.data.routes;
      } catch (error) {
        throw error;
      }
    },
    async fetchUserRoutePermissionsFromBackendAula({ state }, slug_disciplina) {
      try {
        const response = await Axios.useAxios('get', '/user/has-permission-aula/' + slug_disciplina);
        return response.data.status;
      } catch (error) {
        throw error;
      }
    },
    async checkRoutePermission({ state }, routeName) {
      if (!state.userRoutePermissions.length) {
        await this.dispatch('auth/fetchUserRoutePermissionsFromBackend');
      }
      return state.userRoutePermissions.includes(routeName);
    },
    async fetchUserVIP({ commit }) {
      try {
        const response = await getVipData();
        commit('setUserVIP', response.data.status);
        commit('setEmailUserVIP', response.data.email);
        return response.data.status;
      } catch (error) {
        throw error;
      }
    },
  },
  mutations: {
    logMeIn(state) {
      state.userRoutePermissions = [];
      state.loggedIn = true;
    },
    logMeOut(state) {
      state.loggedIn = false;
    },
    setUserRoutePermissions(state, permissions) {
      state.userRoutePermissions = permissions;
    },
    setUserVIP(state, status) {
      state.userVIP = status;
    },
    setEmailUserVIP(state, email) {
      state.emailUserVIP = email;
    }
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    isVip(state, getters, rootState) {
      const productsPaid = rootState.cart.productsPaid;
      if (!productsPaid || productsPaid.length > 3) {
        return false;
      }
      return state.userVIP;
    }
  },
};
