import Axios from '../services/axios.service';
import { saveToLocalStorage, loadFromLocalStorage } from "../assets/js/crypto-utils.js";

function getCartItems() {
  const cartItems = loadFromLocalStorage("cartItems") || [];
  const userData = loadFromLocalStorage("userData") || null;
  const newUserId = userData?.id || null;
  return cartItems.filter((item) => item.userId === newUserId);
}

function saveCartItems(cartItems) {
  saveToLocalStorage("cartItems", cartItems);
}

function saveNotificationCart(bool) {
  saveToLocalStorage("notificationCart", bool);
}

function getNotificationCart() {
  return loadFromLocalStorage("notificationCart") || false;
}


async function getProductsPaid() {
  const userData = loadFromLocalStorage("userData");
  if (!userData || !userData.id) return [];

  try {
    const axios = new Axios();
    const response = await axios.useAxios("get", "disciplinas/vinculadas");
    return response ? response.data : []

  } catch (error) {
    console.error("Erro ao carregar as disciplinas pagas:", error);
    return null;
  }
}

export const cart = {
  namespaced: true,
  state: () => ({
    productsPaid: null,
    cartItems: [],
    notificationCart: getNotificationCart(),
    
  }),
  mutations: {
    SET_CART_ITEMS(state, items) {
      state.cartItems = items;
    },
    ADD_ITEM_TO_CART(state, item) {
      if (state.productsPaid.includes(item.id)) {
        return; 
      }
      state.cartItems.push(item);
    },
    REMOVE_ITEM_FROM_CART(state, itemId) {
      state.cartItems = state.cartItems.filter((item) => item.id !== itemId);
    },
    SET_NOTIFICATION(state) {
      state.notificationCart = true;
    },
    CLEAR_NOTIFICATION(state) {
      state.notificationCart = false;
    },
    SET_PRODUCTS_PAID(state, products) {
      state.productsPaid = products;
    },
    checkCartItem(state) {
      if (state.cartItems.length === 0) {
        this.commit('toggleHideConfig', true);
        this.commit('toggleConfigurator', false);
      } else {
        this.commit('toggleHideConfig', false);
      }
    },
  },
  actions: {
    async updateCartItems({ commit, state }) {
      try {
        const productsPaid = await getProductsPaid();
    
        if (!Array.isArray(productsPaid)) {
          commit("SET_PRODUCTS_PAID", []);
          return;
        }
    
        commit("SET_PRODUCTS_PAID", productsPaid);
    
        const cartItems = getCartItems().filter((item) => !productsPaid.includes(item.id));
        commit("SET_CART_ITEMS", cartItems);
    
        saveCartItems(state.cartItems);
      } catch (error) {
        console.error("Erro ao atualizar os itens do carrinho:", error);
      }
    },
    
    addItemToCart({ state, commit }, item) {
      const userData = loadFromLocalStorage("userData");
      if (!userData || !userData.id) return;

      const userId = userData.id;

      if (state.cartItems.find((i) => i.id === item.id)) return;
      
      if (state.productsPaid.includes(item.id)) {
        return;
      }

      const newItem = { ...item, userId };
      commit("ADD_ITEM_TO_CART", newItem);
      saveCartItems(state.cartItems);
      commit("SET_NOTIFICATION");
      saveNotificationCart(true);
      commit("checkCartItem", state.cartItems);
      
    },
    removeItemFromCart({ state, commit }, itemId) {
      commit("REMOVE_ITEM_FROM_CART", itemId);
      saveCartItems(state.cartItems);
      commit("checkCartItem", state.cartItems);
    },
    clearCart({ commit }) {
      commit("SET_CART_ITEMS", []);
      saveCartItems([]);
    },
    setNotification({ commit }) {
      commit("SET_NOTIFICATION");
      saveNotificationCart(true);
    },
    clearNotification({ commit }) {
      commit("CLEAR_NOTIFICATION");
      saveNotificationCart(false);
    },

  },
  getters: {
    cartItemCount(state) {
      return state.cartItems.length;
    },
    cartTotalPrice(state) {
      return state.cartItems.reduce((total, item) => total + item.preco * item.quantidade, 0);
    },
  },
};
