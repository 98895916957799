import { createRouter, createWebHistory } from "vue-router";
import store from '../store';
import ErrorRoutes from './ErrorRoutes';
import AuthRoutes from './AuthRoutes';
import ExtranetRoutes from './ExtranetRoutes';
import MainRoutes from './MainRoutes';

const routes = [
  {
    path: "",
    component: () => import('@/layouts/extranetLayout.vue'),
    children: [
      { path: "",                name: '',              component: () => import('@/views/LandingPageView.vue')          },
    ]
  },
  ...AuthRoutes,
  ...MainRoutes,
  ...ExtranetRoutes,
  ...ErrorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const middlewares = to.meta.middleware || [];
  
  const runMiddlewares = async (index = 0) => {
    if (index < middlewares.length) {
      const middleware = middlewares[index];
      try {
        const result = await middleware(to, from, next);
        if (result) {
          if (result.route) {
            return next({ name: result.route });
          }
        }
      } catch (error) {
        return next(error);
      }
      runMiddlewares(index + 1);
    } else {
      next();
    }
  };

  runMiddlewares();
});

router.afterEach((to) => {
  if (window.innerWidth < 575) {
    store.commit('navbarMinimize', 'minimize');
  }
});


export default router;