<template>
  <router-view></router-view>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  created() {
    this.initializeApp();
  },
  methods: {
    ...mapActions("auth", ["initializeApp"]),
  }
};
</script>

<style>
</style>