// error routes
export default [
    {
        path: "",
        component: () => import('@/layouts/extranetLayout.vue'),
        children: [
            { path: ":pathMatch(.*)*", name: "404", component: () => import('@/views/auth/error/404.vue') },
            { path: "500", name: "500", component: () => import('@/views/auth/error/500.vue') },
            { path: "403", name: "403", component: () => import('@/views/auth/error/500.vue') }
        ],
    },
]
