import { getVipData } from "../services/main.service";

/**
 * Middleware to check if user has VIP permission
 * 
 * @param {Object} to 
 * @param {Object} from 
 * @param {Function} next 
 * @returns {Object}
 */
export default async function vip(to, from, next) {
  try {
    const response = await getVipData();
    const hasPermission = response.data.status;
    if (hasPermission) {
      return { status: true };
    } else {
      return { status: false, route: 'Home' };
    }
  } catch (error) {
    return  { status: false, route: 'Home' };
  }
}
