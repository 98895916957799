// Main Routes

import auth from '@/middlewares/auth.js'
import permission from '@/middlewares/permission.js'
import aulaPermission from '@/middlewares/aula-permission.js'
import vip from '@/middlewares/vip.js'

export default [
    {
        path: "/",
        component: () => import('@/layouts/defaultLayout.vue'),
        // rotas internas que precisam de verificação de permissoes do banco e autenticação de login
        meta: { middleware: [auth, permission] },
        children: [
          { path: "cursos/meus-cursos/",            name: "Meus cursos",        component: () => import('@/views/MeusCursosView.vue')                    },
          { path: "cursos/comprar",                 name: "Comprar Curso",      component: () => import('@/views/ComprarCursoView.vue')                          },
          { path: "cursos/aulas/:curso/:disciplina",name: "Aulas",              component: () => import('@/views/AulasView.vue'), meta: { parent: "Meus cursos", middleware: [aulaPermission] }},
          { path: "sistema/perfilacesso",           name: "Perfil de Acesso",   component: () => import('@/views/PerfilAcesso.vue')                      },
          { path: "sistema/usuarios",               name: "Usuários",           component: () => import('@/views/UsuariosView.vue')                      },
          { path: "sistema/acoes",                  name: "Ações",              component: () => import('@/views/AcoesView.vue')                         },
          { path: "sistema/modulos",                name: "Módulos",            component: () => import('@/views/ModulosView.vue')                       },
          { path: "sistema/paginas",                name: "Páginas",            component: () => import('@/views/PaginasView.vue')                       },
          { path: "sistema/logs",                   name: "Logs",               component: () => import('@/views/LogsView.vue')                          },
          { path: "sistema/codigos-promocionais",   name: "Códigos Promocionais",component: () => import('@/views/CodigosPromocionaisView.vue')             },
          { path: "usuario/historico",              name: "Histórico de Compras",          component: () => import('@/views/HistoricoView.vue')                  },
          { path: "usuario/notificacoes",           name: "Notificações",       component: () => import('@/views/NotificacoesView.vue')                  },
          { path: "usuario/documentos",             name: "Documentos",         component: () => import('@/views/DocumentosView.vue')                  },
          { path: "pedagogico/cursos",              name: "Cursos",             component: () => import('@/views/PedagogicoCursosView.vue')              },
          { path: "pedagogico/microcertificacoes",  name: "Microcertificações", component: () => import('@/views/PedagogicoMicrocertificacoesView.vue')  },
          { path: "pedagogico/disciplinas",         name: "Disciplinas",        component: () => import('@/views/PedagogicoDisciplinasView.vue') },
          { path: "pedagogico/disciplinas/:disciplina/aulas", name: "Disciplina Aulas", component: () => import('@/views/PedagogicoAulasView.vue'), meta: { parent: "Disciplinas" } },
          { path: "pedagogico/exercicios",        name: "Exercícios Provas",        component: () => import('@/views/PedagogicoExercicios.vue') },
        ]
      },
      {
          path: "/",
          component: () => import('@/layouts/defaultLayout.vue'),
          // rotas internas que NÃO precisam de verificação de permissoes do banco, apenas de autenticação de login
          meta: { middleware: [auth] },
          children: [
            { path: "home",                           name: "Home",               component: () => import('@/views/HomeView.vue')                          },
            { path: "curso-desconto-vip",             name: "Curso Desconto VIP",           component: () => import('@/views/ComprarCursoVIP.vue'), meta: { middleware: [vip] },  }
          ]
        },
]
